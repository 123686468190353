.breadcrumbs {
    display: inline-block;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 30px;
    .breadcrumbs-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        ul.breadcrumbs-list {
            display: inline-block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                display: inline;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
                margin-right: 7px;
                color: $gray-base;
                a {
                    color: $gray;
                    text-decoration: none;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-primary;
                        transition: all 300ms;
                    }
                }
                span {

                }
                &.sep {
                    svg {
                        padding-top: 6px;
                        width: 16px;
                        height: 9px;
                    }
                }
            }
        }
    }
}

@import "../media/tablet/includes/breadcrumbs";
@import "../media/mobile/includes/breadcrumbs";
