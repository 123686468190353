@media (max-width: $screen-sm) {
    // sm-view
    .cart-progress {
        margin: 30px 0 22px;
    }

    .cart-total-block {
        margin: 0 0 39px;
        .cart-total-block-holder {
            .back {
                width: 100%;
                margin: 0 0 25px;
                .btn {
                    padding: 19px 0 15px;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }

            .notes {
                width: 100%;
                margin: 0 0 11px;
                .notes-holder {
                    .note {
                        height: 81px;
                        padding: 0 19px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 130%;
                    }
                }
            }

            .summ {
                width: 100%;
                .summ-holder {
                    width: 100%;
                    .sub-total {
                        margin: 0 0 16px;
                        .name {

                        }

                        .value {
                            line-height: 20px;
                            box-sizing: border-box;
                            padding: 0 3px 0 0;
                        }
                    }

                    .total {
                        .name {
                            width: auto;
                        }

                        .value {
                            float: right;
                            width: auto;
                            font-size: 21px;
                            line-height: 21px;
                            .old-value {
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    .cart-empty {
        margin: -6px 0 51px;
        .cart-empty-holder {
            .icon {
                margin-bottom: 16px;
                svg {
                    width: 82px;
                    height: 110px;
                }
            }

            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%;
                margin: 0 0 10px;
                box-sizing: border-box;
                padding: 0 2%;
            }

            .text {
                margin: 0 0 11px;
            }

            .action {
                .btn {

                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
