@media (max-width: $screen-lg) {
    // lg-md view
    .orders-list {
        .orders-list-holder {
            .orders-tabs {
                margin-bottom: 4px;
                .heading {
                    .heading-holder {
                        width: calc(100% + 28px);
                        margin-left: -14px;
                        a {
                            margin: 0 14px;
                        }
                    }
                }
            }
        }
    }

    .order-list {
        table {
            thead {
                tr {
                    th {
                        padding-top: 19px;
                    }

                    th.id {
                        width: 15.2%;
                    }

                    th.status {
                        width: 26.2%;
                    }

                    th.paying {
                        width: 29.2%;
                    }

                    th.total {
                        width: 15.5%;
                    }

                    th.action {
                        padding-left: 0.8%;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        height: 80px;
                        div.action {
                            margin-right: 26px;
                        }

                        div.price {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 130%;
                        }
                    }
                }
            }
        }
    }

    .buyer-info {
        .heading {
            h3, .h3 {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%;
            }
        }

        .infoes {
            .info {
                .info-holder {
                    width: 54.6%;
                }

                .action {
                    width: 45.4%;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
