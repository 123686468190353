.order-form {
    display: inline-block;
    width: 100%;
    margin: 0 0 18px;
    .order-form-holder {
        display: inline-block;
        width: 100%;
        form {

            .note {
                display: inline-block;
                width: 100%;
                margin: 0 0 9px;
            }

            .form-field {
                margin-bottom: 20px;
                label.input {
                    &.addon {
                        input {
                            padding: 0px 30px 0 48px;
                        }
                    }

                    .addon {
                        left: 13px;
                        right: auto;
                        bottom: 27px;
                        a {
                            color: #959EA8;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                    }

                    .name {

                    }

                    input, textarea {
                        background: white;
                    }
                }

                label.checkbox {

                    &:hover input ~ .checkmark {
                        border: 1px solid $brand-primary;
                    }

                    input {
                        &:checked ~ .checkmark {
                            border: 1px solid $brand-primary;
                        }
                    }

                    .checkmark {
                        border: 1px solid #DFE5EA;
                        box-sizing: border-box;
                        border-radius: 5px;
                    }

                    .name {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 130%;
                    }

                }

                label.inner-checkbox {
                    width: 100%;
                    height: 100%;
                    &:hover {
                        .checkmark {
                            background: #F6FCF6;
                        }
                    }
                    input {
                        &:checked ~ .checkmark {
                            border: 1px solid $brand-primary;
                            background: #F6FCF6;
                            box-shadow: 0px 10px 20px rgba(150, 163, 173, 0.25);
                            &:before {
                                opacity: 1;
                                transition: all 300ms;
                            }
                            .inner-checkmark {
                                .icon {
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                    .checkmark {
                        width: 100%;
                        height: 100%;
                        border: 1px solid #DFE5EA;
                        box-sizing: border-box;
                        border-radius: 10px;
                        padding: 17px 30px 14px;
                        &:before {
                            content: "";
                            display: inline-block;
                            position: absolute;
                            z-index: 0;
                            left: -1px;
                            top: -1px;
                            width: calc(100% - 1px);
                            height: calc(100% - 1px);
                            border: 2px solid $brand-primary;
                            opacity: 0;
                            transition: all 300ms;
                            border-radius: 10px;
                        }
                        &:after {
                            display: none;
                        }
                        .inner-checkmark {
                            display: inline-block;
                            width: 100%;
                            .icon {
                                display: inline-block;
                                float: left;
                                width: 30px;
                                height: 30px;
                                color: #959EA8;
                                svg {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .text {
                                display: inline-block;
                                float: left;
                                width: calc(100% - 30px);
                                box-sizing: border-box;
                                padding: 3px 0 0 14px;
                                .name {
                                    width: 100%;
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 16px;
                                    line-height: 150%;
                                    margin: 0 0 3px
                                }
                                .price {
                                    width: 100%;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 18px;
                                    line-height: 150%;
                                }
                            }
                        }
                    }
                }
            }

            .order-form-block {
                display: inline-block;
                width: 100%;
                background: #F7F7F7;
                box-sizing: border-box;
                padding: 30px 40px 10px;
                margin-bottom: 10px;
                .order-form-block-holder {
                    display: inline-block;
                    width: 100%;
                    .sub-heading {
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 150%;
                        margin-bottom: 20px;
                    }

                    .form-group.group-inner-checkbox {
                        width: calc(100% + 35px);
                        margin-left: -17.5px;
                        margin-bottom: 30px;
                        .form-col {
                            padding: 0 17.5px;
                            .form-field {
                                margin: 0;
                            }
                        }
                    }

                    .form-group.custom-contacts-cols {
                        display: flex;
                        width: calc(100% + 51px);
                        margin: 0;
                        .form-col {
                            width: auto;
                            padding: 0;
                            margin-right: 51px;
                        }
                    }

                    .form-group.flex-group {
                        display: flex;
                        flex-wrap: wrap;
                        .form-col {
                            height: auto;
                            .form-field {
                                height: 100%;
                            }
                        }
                    }

                    .form-group {

                        .form-col {

                            .form-field {

                                .checkbox {

                                    input {

                                    }

                                    .checkmark {

                                    }

                                    .name {

                                    }
                                }
                            }
                        }
                    }

                    .fields-tabs {
                        display: inline-block;
                        width: 100%;
                        position: relative;
                        transition: all 300ms;
                        .fields-tab.current {
                            opacity: 1;
                            z-index: 1;
                            overflow: visible;
                            transition: all 300ms;
                        }

                        .fields-tab {
                            display: inline-block;
                            width: 100%;
                            overflow: hidden;
                            opacity: 0;
                            transition: all 300ms;
                            position: absolute;
                            left: 0;
                            top: 0;
                            z-index: -1;
                            .form-group {

                                .form-col {

                                    .form-field {

                                        .input {

                                            .name {

                                            }

                                            input {

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/personal/order_form";
@import "../../media/mobile/includes/personal/order_form";
