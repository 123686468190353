.orders-list {
    display: inline-block;
    width: 100%;
	.orders-list-holder {
        display: inline-block;
        width: 100%;
		.orders-tabs {
            display: inline-block;
            width: 100%;
            margin-bottom: 7px;
			.heading {
                display: inline-block;
                width: 100%;
                border-bottom: 1px solid #DFE5EA;
                margin-bottom: 20px;
				.heading-holder {
                    display: inline-block;
                    width: calc(100% + 30px);
                    margin-left: -15px;
                    padding-bottom: 2px;
					a {
                        margin: 0 15px;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 150%;
                        color: #545B63;
                        position: relative;
                        &.current {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 130%;
                            color: $brand-primary;
    						.text {

    						}
                            &:after {
                                opacity: 1;
                                transition: all 300ms;
                            }
    					}

						.text {

						}
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 0;
                            border-top: 3px solid $brand-primary;
                            opacity: 0;
                            position: absolute;
                            left: 0;
                            bottom: -10px;
                            transition: all 300ms;
                        }
					}
				}
			}

			.tabs {

				.tab {

				}
			}
		}
	}
}

.order-list {
    display: inline-block;
    width: 100%;
	table {
        width: 100%;
		thead {

			tr {
                background: #F7F7F7;
                th {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 130%;
                    color: $gray-dark;
                    text-align: left;
                    box-sizing: border-box;
                    width: auto;
                    text-align: left;
                    padding-top: 18px;
                }

				th.id {
                    box-sizing: border-box;
                    padding-left: 15px;
                    width: 12.9%;
                    padding-top: 12px;
                    padding-bottom: 11px;
                    vertical-align: top;
				}

				th.status {
                    width: 23.2%;
				}

				th.paying {
                    width: 21.2%;
				}

				th.total {
                    width: 19.9%;
				}

				th.action {
                    padding-left: 1.5%;
				}
			}
		}

		tbody {

			tr {
                border-bottom:  1px solid #DFE5EA;
                td.id {
                    padding-left: 16px;
                }
				td {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                    text-align: left;
                    box-sizing: border-box;
                    height: 78px;
                    vertical-align: middle;
					div.id {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 130%;
					}

					div.date {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 130%;
                        color: $gray-light;
					}

                    div.status {
                        display: inline-block;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 150%;
                        background: $gray;
                        color: white;
                        border-radius: 20px;
                        padding: 3px 12px 6px;
                        margin-left: -5px;
                        &.red {
                            color: #E22F2F;
                            background: #FFE4E4;
                        }
                        &.green {
                            color: #00914C;
                            background: #E9F9EA;
                        }
                        &.yellow {
                            background: #FFF6E7;
                            color: #CCA765;
                        }
                    }

                    div.action {
                        display: inline-block;
                        float: left;
                        margin-right: 20px;
                        &:last-of-type {
                            margin-right: 0;
                        }
                        a {
                            display: inline-block;
                            float: left;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 130%;
                            color: black;
                            &:hover {
                                color: $brand-primary;
                            }
                            .icon {
                                display: inline-block;
                                float: left;
                                margin-right: 5px;
                            }

                            .text {
                                display: inline-block;
                                float: left;
                            }
                        }
                    }

                    div.price {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 150%;
                    }
				}
			}
		}
	}
}

.buyer-info {

	.heading {
        display: inline-block;
        width: 100%;
        margin: 0 0 13px;
		h3, .h3 {
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 150%;
            font-family: $accent_font;
		}
	}

	.infoes {
        display: inline-block;
        width: 100%;
		.info {
            display: inline-block;
            width: 100%;
            margin: 0 0 19px;
			.info-holder {
                display: inline-flex;
                float: left;
                width: 46%;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 14px;
				.name {

				}

				.line {
                    flex-grow: 1;
                    border-bottom: 1px dashed #DFE5EA;
                    height: 14px;
				}

				.value {
                    min-width: 136px;
                    text-align: left;
				}
			}

			.action {
                display: inline-block;
                float: left;
                width: 50%;
				a {
                    display: inline-block;
                    float: left;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 130%;
					.icon {
                        display: inline-block;
                        float: left;
                        margin-right: 7px;
						svg {

							path {

							}
						}
					}

					.text {
                        display: inline-block;
                        float: left;
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/personal/orders";
@import "../../media/mobile/includes/personal/orders";
