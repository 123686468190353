@media (max-width: $screen-lg) {
    // lg-md view
    .breadcrumbs {
        margin-top: 11px;
        margin-bottom: 21px;
    }
}

@media (max-width: $screen-md) {
    // md-view
}
