@media (max-width: $screen-lg) {
    // lg-md view
    .cart-table {
        .cart-table-holder {
            table {
                thead {

                    tr {
                        th {

                        }

                        th.item {
                            padding: 0 0 0 25px;
                        }
                        th.price {
                            width: 17.7%;
                        }
                        th.count {
                            width: 17.5%;
                            padding: 0 0 0 53px;
                        }
                        th.total {
                            width: auto;
                            padding: 0 0 0 25px;
                            text-align: left;
                        }
                        th.action {
                            display: none;
                        }
                    }
                }

                tbody {
                    tr {
                        position: relative;
                        td {

                            .amount {
                                width: 110px;
                            }

                            .item-desc {
                                padding: 44px 0 23px 11px;
                                min-height: 200px;
                                align-items: flex-start;
                                .image {
                                    width: 80px;
                                    height: 98px;
                                }

                                .text {
                                    width: calc(100% - 80px);
                                    padding-left: 8px;
                                    .text-holder {
                                        .name {
                                            a {

                                            }
                                        }

                                        .desc {
                                            margin-bottom: 6px;
                                            .prop {

                                            }
                                        }

                                        .add {

                                        }
                                    }
                                }
                            }

                            .price {
                                font-family: $accent_font;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 16px;
                                margin: 0 0 0 -10px;
                            	.old-price {
                                    font-size: 12px;
                                    line-height: 12px;
                                    margin-right: 3px;
                            	}

                            	.value {

                            	}
                            }
                            .price.big {
                                font-size: 21px;
                                line-height: 21px;
                                margin: 0 0 0 0;
                                .old-price {
                                    font-size: 14px;
                                    line-height: 14px;
                                }

                                .value {

                                }
                            }
                        }

                        td.action {
                            display: inline-block;
                            position: absolute;
                            right: 0;
                            top: 20px;
                            width: 24px;
                            height: 24px;
                        }

                        td.total {
                            text-align: right;
                            padding: 0 22px 0 0;
                        }
                    }

                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
