@media (max-width: $screen-sm) {
    // sm-view
    .orders-list {
        .orders-list-holder {
            .orders-tabs {
                margin-bottom: 9px;
                .heading {
                    margin-bottom: 33px;
                    .slick-slider.full {
                        .slick-list {
                            width: 100%;
                            margin: 0;
                            .slick-track {
                                .slick-slide {
                                    padding: 0 14px;
                                }
                            }
                        }
                    }

                    .heading-holder {
                        width: calc(100% + 28px);
                        margin-left: -14px;
                        a {
                            margin: 0;
                            line-height: 116%;
                        }
                    }
                }
            }
        }
    }

    .order-list {
        display: inline-block;
        width: 100%;
        table {
            display: inline-block;
            width: 100%;
            thead {
                display: none;
                tr {
                    th {
                        padding-top: 19px;
                    }

                    th.id {
                        width: 15.2%;
                    }

                    th.status {
                        width: 26.2%;
                    }

                    th.paying {
                        width: 29.2%;
                    }

                    th.total {
                        width: 15.5%;
                    }

                    th.action {
                        padding-left: 0.8%;
                    }
                }
            }

            tbody {
                display: inline-block;
                width: 100%;
                tr {
                    display: inline-block;
                    width: 100%;
                    border-bottom: 1px solid #DFE5EA;
                    margin: 0 0 18px;
                    td {
                        display: inline-block;
                        float: left;
                        width: auto;
                        height: 53px;
                        box-sizing: border-box;

                        div.status {

                        }

                        div.price {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 150%;
                        }

                        div.action {
                            margin-right: 19px;
                        }
                    }

                    td.id {
                        width: 40%;
                        padding: 0;
                    }

                    td.status {
                        width: 60%;
                        text-align: right;
                    }

                    td.paying {
                        width: 50%;
                    }

                    td.total {
                        width: 50%;
                        text-align: right;
                        padding-right: 14px;
                    }

                    td.action {
                        width: 100%;
                        height: 33px;
                    }


                }
            }
        }
    }

    .buyer-info {
        .heading {
            h3, .h3 {
                margin: 0 0 11px;   
            }
        }

        .infoes {
            .info {
                .info-holder {
                    width: 95%;
                }

                .action {
                    width: 5%;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
