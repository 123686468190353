@media (max-width: $screen-sm) {
    // sm-view
    .person-data {
        .person-data-holder {
            form {
                .form-group {
                    width: 100%;
                    margin-left: 0;
                    .form-col {
                        padding: 0;
                        &.col-50 {
                            width: 100%;
                        }
                    }

                    .form-field {
                        margin-bottom: 17px;
                    }
                }

                .action {
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
