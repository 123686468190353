.cart-table {
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
    .cart-table-holder {
        display: inline-block;
        width: 100%;
        table {
            width: 100%;
            thead {

                tr {
                    border-bottom: 1px solid #DFE5EA;
                    th {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 10px;
                        line-height: 130%;
                        text-transform: uppercase;
                        color: $gray;
                        height: 22px;
                        text-align: center;
                    }

                    th.item {
                        width: 41%;
                    }

                    th.price {
                        width: 12.4%;
                    }

                    th.count {
                        width: 26.7%;
                    }

                    th.total {
                        width: 12%;
                    }

                    th.action {
                        width: auto;
                    }
                }
            }

            tbody {

                tr {
                    border-bottom: 1px solid #DFE5EA;
                    td {
                        text-align: center;
                        vertical-align: middle;
                        .action {
                            width: 100%;
                            text-align: right;
                            box-sizing: border-box;
                            padding: 0 10px 0 0;
                            a {
                                &:hover {
                                    opacity: 0.5;
                                }
                            }
                        }

                        .amount {
                            width: 170px;
                            box-sizing: border-box;
                        }

                        .item-desc {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            box-sizing: border-box;
                            padding: 30px 0 30px 11px;
                            .image {
                                width: 135px;
                                height: 166px;
                                .image-holder {
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    a {
                                        display: inline-block;
                                        width: 100%;
                                        height: 100%;
                                        overflow: hidden;
                                        &:hover {
                                            img {
                                                opacity: 0.68;
                                                transition: all 300ms;
                                            }
                                        }
                                        img {
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            transition: all 300ms;
                                        }
                                    }
                                }
                            }

                            .text {
                                width: calc(100% - 135px);
                                box-sizing: border-box;
                                padding-left: 30px;
                                .text-holder {
                                    display: inline-block;
                                    width: 100%;
                                    .name {
                                        display: inline-block;
                                        width: 100%;
                                        margin-bottom: 7px;
                                        text-align: left;
                                        a {
                                            font-family: $accent_font;
                                            font-style: normal;
                                            font-weight: normal;
                                            font-size: 14px;
                                            line-height: 130%;
                                            color: $gray-base;
                                            &:hover {
                                                color: $brand-primary;
                                            }
                                        }
                                    }

                                    .desc {
                                        display: inline-block;
                                        width: 100%;
                                        margin-bottom: 9px;
                                        text-align: left;
                                        .prop {
                                            display: inline-block;
                                            width: 100%;
                                            font-style: normal;
                                            font-weight: 300;
                                            font-size: 12px;
                                            line-height: 130%;
                                            color: $gray;
                                        }
                                    }

                                    .add {
                                        display: inline-block;
                                        width: 100%;
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 12px;
                                        line-height: 130%;
                                        color: $gray;
                                        text-align: left;
                                    }
                                }
                            }
                        }

                        .price {
                            font-family: $accent_font;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 18px;
                            margin: 10px 0 0;
                        	.old-price {
                                font-size: 14px;
                                line-height: 18px;
                                text-decoration-line: line-through;
                                color: $gray;
                                margin-right: 10px;
                        	}

                        	.value {

                        	}
                        }
                        .price.big {
                            font-size: 24px;
                            line-height: 24px;
                            margin: 4px 4px 0 0;
                            .old-price {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/personal/cart_table";
@import "../../media/mobile/includes/personal/cart_table";
