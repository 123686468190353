.cart-progress {
    display: inline-block;
    width: 100%;
    margin: 43px 0 31px;
    .cart-progress-holder {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        padding: 0 32px;
        position: relative;
        &:after {
            content: "";
            display: inline-block;
            width: calc(100% - 80px);
            margin-left: 40px;
            height: 1px;
            background-image: url(../img/cart-progress.png);
            background-position: center;
            background-repeat: repeat-x;
            background-clip: border-box;
            background-size: 100%;
            position: absolute;
            left: 0;
            top: 19px;
            z-index: 1;
        }
        .position.current {
            .posoition-holder {
                .name {
                    color: $gray-base;
                }

                .number {
                    border: 2px solid $brand-primary;
                    background: white;
                    color: $brand-primary;
                    box-shadow: 0px 10px 8px rgba(0, 144, 76, 0.15);
                }
            }
        }
        .position.done {
            .posoition-holder {
                .name {
                    color: $gray-base;
                }

                .number {
                    border: 2px solid $brand-primary;
                    background: $brand-primary;
                    color: white;
                    box-shadow: 0px 10px 8px rgba(0, 144, 76, 0.15);
                }
            }
        }
        .position {
            position: relative;
            display: inline-block;
            z-index: 2;
            .posoition-holder {
                display: inline-block;
                .name {
                    height: 27px;
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 150%;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: calc(100% + 14px);
                    color: $gray;
                    transition: all 300ms;
                }

                .number {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 25px;
                    text-align: center;
                    height: 40px;
                    width: 40px;
                    box-sizing: border-box;
                    padding: 3px 0 0;
                    border-radius: 50%;
                    border: 2px solid #EDF3F8;
                    background: #EDF3F8;
                    color: #959EA8;
                    transition: all 300ms;
                }
            }
        }
    }
}

.cart-total-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 78px;
    .cart-total-block-holder {
        display: inline-block;
        width: 100%;
        .back {
            display: inline-block;
            float: left;
            width: 50%;
            .btn {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-transform: uppercase;
                padding: 15px 28px;
                border: 1px solid #00914C;
                border-radius: 10px;
                &:hover {
                    background: #00914C;
                }
                .icon {
                    display: inline-block;
                    float: left;
                    margin-right: 8px;
                    width: 13px;
                    height: 9px;
                    padding-top: 5px;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                .text {
                    display: inline-block;
                    float: left;
                }
            }
        }

        .notes {
            display: inline-block;
            width: 57%;
            float: left;
            .notes-holder {
                display: inline-block;
                width: 100%;
                .note {
                    display: flex;
                    align-items: center;
                    height: 55px;
                    background: #F5FCF6;
                    border: 1px dashed #00914C;
                    box-sizing: border-box;
                    padding: 0 30px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 130%;
                    margin: 0 0 10px;
                    .icon {
                        margin-right: 20px;
                        svg {

                        }
                    }

                    .text {

                    }
                }
            }
        }

        .summ.adjust {
            .summ-holder {
                margin: 2px 3px 0 0;
                .sub-total {
                    margin: 0 0 9px;
                }
            }
        }

        .summ {
            display: inline-block;
            width: 43%;
            float: right;
            .summ-holder {
                display: inline-block;
                width: 73%;
                float: right;
                .sub-total {
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 13px;
                    .name {
                        display: inline-block;
                        float: left;
                        width: 50%;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 20px;
                    }

                    .value {
                        display: inline-block;
                        float: left;
                        width: 50%;
                        text-align: right;
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 27px;
                    }
                }

                .total {
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 21px;
                    .name {
                        display: inline-block;
                        float: left;
                        width: 50%;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .value {
                        display: inline-block;
                        float: left;
                        width: 50%;
                        text-align: right;
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        line-height: 24px;
                        .old-value {
                            font-size: 16px;
                            line-height: 24px;
                            margin-right: 14px;
                        }

                        .current-value {

                        }
                    }
                }

                .action {
                    display: inline-block;
                    width: 100%;
                    .btn {
                        width: 100%;
                        padding: 15px 0;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        text-transform: uppercase;
                        text-align: center;
                        border-radius: 10px;
                    }
                }

                .sub-note {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 130%;
                    color: $gray;
                    margin: 12px 0 0;
                    a {
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }
}

.cart-empty {
    display: inline-block;
    width: 100%;
    margin: 9px 0 51px;
    min-height: 420px;
    .cart-empty-holder {
        display: inline-block;
        width: 100%;
        .icon {
            display: inline-block;
            width: 100%;
            text-align: center;
            margin-bottom: 19px;
            svg {

                path {

                }
            }
        }

        .heading {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            margin: 0 0 19px;
        }

        .text {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            margin: 0 0 20px;
            a {

            }
        }

        .action {
            display: inline-block;
            width: 100%;
            text-align: center;
            .btn {
                font-family: $accent_font;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 130%;
                border: 1px solid rgba(0, 121, 64, 0.2);
                box-sizing: border-box;
                border-radius: 70px;
                padding: 15px 49px;
                &:hover {
                    border: 1px solid $brand-primary;

                }
            }
        }
    }
}

@import "../../media/tablet/includes/personal/cart";
@import "../../media/mobile/includes/personal/cart";
