@media (max-width: $screen-sm) {
    // sm-view
    .order-success {
        margin: 0px 0 38px;
        min-height: 494px;
        .order-success-holder {
            padding: 19px 19px 156px;
            background-position: center 92%;
            background-size: 160px 102px;
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%;
                a {

                }
            }

            .text {
                p {

                }
            }

            .note {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 130%;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
