@media (max-width: $screen-sm) {
    // sm-view
    .cart-table {
        .cart-table-holder {
            table {
                thead {
                    display: none;
                }

                tbody {
                    display: inline-block;
                    width: 100%;
                    tr {
                        display: inline-block;
                        width: 100%;
                        padding: 0 0 21px;
                        td {
                            box-sizing: border-box;
                            .item-desc {
                                padding: 18px 34px 15px 0;
                                min-height: auto;
                                .image {

                                }

                                .text {
                                    padding-left: 10px;
                                    .text-holder {
                                        .name {
                                            box-sizing: border-box;
                                            margin-bottom: 1px;
                                        }

                                        .desc {
                                            margin-bottom: 2px;
                                        }
                                    }
                                }
                            }

                            .price {
                                margin: 0;
                            }

                            .amount {
                                width: 130px;
                            }
                        }

                        td.item {
                            display: inline-block;
                            width: 100%;
                            margin: 0 0 4px;
                        }

                        td.price {
                            display: inline-block;
                            width: 100%;
                            box-sizing: border-box;
                            padding-left: 90px;
                            text-align: left;
                            height: 34px;
                        }

                        td.count {
                            display: inline-block;
                            float: left;
                            width: 50%;
                            text-align: left;
                        }

                        td.total {
                            display: inline-block;
                            float: left;
                            width: 50%;
                            padding: 13px 4px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
