@media (max-width: $screen-sm) {
    // sm-view
    .order-form {
        .order-form-holder {
            form {
                .note {
                    p {
                        margin: 0 0 9px;
                    }
                }

                .form-field {
                    margin-bottom: 18px;
                    label.input {}

                    label.checkbox {
                        input {

                        }

                        .checkmark {

                        }

                        .name {
                            margin: 2px 0 0 10px;
                        }
                    }

                    label.inner-checkbox {
                        .checkmark {
                            padding: 19px 19px 14px;
                            .inner-checkmark {
                                .icon {}

                                .text {
                                    .name {

                                    }

                                    .price {

                                    }
                                }
                            }
                        }
                    }
                }

                .order-form-block {
                    width: 110.35%;
                    box-sizing: border-box;
                    padding: 19px 5.175% 10px;
                    margin-left: -5.175%;
                    margin-bottom: 6px;
                    .order-form-block-holder {

                        .sub-heading {}

                        .form-group {
                            width: 100%;
                            margin: 0;
                            .form-col {
                                padding: 0;
                                &.col-50 {
                                    width: 100%;
                                    padding: 0;
                                }
                            }
                        }

                        .form-group.group-inner-checkbox {
                            width: 100%;
                            margin-left: 0;
                            margin-bottom: 11px;
                            .form-col {
                                &.col-33 {
                                    width: 100%;
                                    padding: 0;
                                }
                                .form-field {
                                    margin: 0 0 8px;
                                }
                            }
                        }

                        .form-group.custom-contacts-cols {
                            width: 100%;
                            flex-wrap: wrap;
                            .form-col {
                                width: 100%;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
