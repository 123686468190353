@media (max-width: $screen-sm) {
    // sm-view
    .breadcrumbs {
        margin-top: 9px;
        margin-bottom: 21px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
