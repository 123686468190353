@media (max-width: $screen-lg) {
    // lg-md view
    .cart-progress {
        margin: 21px 0 22px;
        .cart-progress-holder {
            padding: 0 26px;
            &:after {
                top: 15px;
            }
            .position {
                .posoition-holder {
                    .name {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 130%;
                        bottom: calc(100% + 2px);
                    }

                    .number {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 150%;
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }

    .cart-total-block {
        margin: 0 0 47px;
        .cart-total-block-holder {
            .back {
                width: 49%;
            }

            .notes {
                width: 42%;
                .notes-holder {
                    .note {
                        height: 81px;
                        padding: 0 19px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 130%;
                    }
                }
            }

            .summ {
                width: 51%;
                .summ-holder {
                    width: 99.2%;
                }
            }
            .summ.adjust {
                .summ-holder {
                    margin: -2px 1px 0 0;
                }
            }
        }
    }

    .cart-empty {
        margin: -26px 0 51px;
        .cart-empty-holder {
            .icon {
                margin-bottom: 16px;
                svg {
                    width: 82px;
                    height: 110px;
                }
            }

            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
                margin: 0 0 10px;
            }

            .text {
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 150%;
                margin: 0 0 11px;
            }

            .action {
                .btn {

                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
