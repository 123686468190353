.pagination {
    display: inline-block;
	ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        >li {
            display: inline-block;
            float: left;
            margin: 0 1px;
			a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                box-sizing: border-box;
                border: 1px solid transparent;
                border-radius: 50%;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 150%;
                color: $gray-base;
                text-decoration: none;
                transition: all 300ms;
                background: white;
                &:hover {
                    color: $brand-primary;
                    transition: all 300ms;
                }
                &.disabled {
                    color: $gray-light;
                    opacity: 0.3;
                    &:hover {
                        background-color: white;
                        color: $gray-light;
                        opacity: 0.3;
                        cursor: default;
                    }
                }
                &.current {
                    border: 1px solid $brand-primary;
                    &:hover {
                        border: 1px solid $brand-primary;
                        cursor: default;
                    }
                }
                &.prev, &.next {
                    margin: 0 1px 0 0;
                }
                &.next {
                    margin: 0 0 0 1px;
                }
                &.first, &.last {

                }
                svg {

                }
			}
        }
	}
}

.pagination-container {
    display: flex;
    justify-content: center;
}
