@media (max-width: $screen-sm) {
    // sm-view
    .personal-page {
        margin: 0 0 43px;
        overflow: hidden;
        .personal-page-holder {
            .menu-col {
                .menu-col-holder {
                    .greetings {
                        br {
                            display: inline-block;
                        }
                    }

                    .personal-menu {
                        .personal-menu-holder {
                            .slick-slider.full {
                                .slick-list {
                                    .slick-track {
                                        .slick-slide {
                                            padding: 0 5px;
                                        }
                                    }
                                }
                            }

                            ul {
                                width: calc(100% + 10px);
                                margin-left: -5px;
                                li {
                                    margin: 0;
                                    a {
                                        padding: 6px 14px 7px 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
