.person-data {
    .person-data-holder {
        form {
            .form-group {
                width: calc(100% + 20px);
                margin-left: -10px;
                .form-col {
                    padding: 0 10px;
                }
            }

            .action {
                .btn {
                    width: calc(50% - 10px);
                    padding: 15px 0;
                    text-align: center;
                    border-radius: 10px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@import "../../media/tablet/includes/personal/data";
@import "../../media/mobile/includes/personal/data";
