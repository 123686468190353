@import "parameters";
@import "mixins";
@import "includes/breadcrumbs";
@import "includes/pagination";
@import "includes/personal/data";
@import "includes/personal/orders";
@import "includes/personal/cart";
@import "includes/personal/cart_table";
@import "includes/personal/order_form";
@import "includes/personal/order_success";
@import "includes/common/amount_counter";

.personal-page {
    display: inline-block;
    width: 100%;
    margin: 0 0 83px;
    .personal-page-holder {
        display: flex;
        width: 100%;
        .menu-col {
            display: inline-block;
            width: 255px;
            height: auto;
            float: left;
            .menu-col-holder {
                display: inline-block;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                border-right: 1px solid #DFE5EA;
                .greetings {
                    display: inline-block;
                    width: 100%;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 150%;
                    margin-bottom: 21px;
                    br {

                    }
                }

                .personal-menu {
                    display: inline-block;
                    width: 100%;
                    .personal-menu-holder {
                        display: inline-block;
                        width: 100%;
                        ul {
                            display: inline-block;
                            width: 100%;
                            padding: 0;
                            margin: 0;
                            li {
                                display: inline-block;
                                width: 100%;
                                a {
                                    display: flex;
                                    width: 100%;
                                    min-height: 70px;
                                    align-items: center;
                                    text-decoration: none;
                                    box-sizing: border-box;
                                    padding: 5px 10px;
                                    border-bottom: 1px solid #DFE5EA;
                                    &:hover {
                                        background: #F7F7F7;
                                    }
                                    &.current {
                                        background: #F7F7F7;
                                        .icon {
                                            color: $brand-primary;
                                        }
                                        .text {
                                            color: $brand-primary;
                                            font-weight: 600;
                                        }
                                    }
                                    .icon {
                                        width: 24px;
                                        height: 24px;
                                        color: #959EA8;
                                        margin-right: 15px;
                                        svg {
                                            width: 100%;
                                            height: 100%;
                                            path {

                                            }
                                        }
                                    }

                                    .text {
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 16px;
                                        line-height: 150%;
                                        color: $gray-base;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .personal-info {
            display: inline-block;
            width: calc(100% - 255px);
            .personal-info-holder {
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding-left: 30px;
                padding-right: 6px;
                h2, .h2 {
                    font-family: $accent_font;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 150%;
                    margin: 3px 0 18px;
                }
                .note {
                    display: inline-block;
                    width: 100%;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }
    }
}

@import "media/tablet/personal";
@import "media/mobile/personal";
