@media (max-width: $screen-lg) {
    // lg-md view
    .order-form {
        margin: 0 0 11px;
        .order-form-holder {
            form {
                .note {
                    p {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }

                .form-field {
                    label.input {}

                    label.checkbox {
                        input {

                        }

                        .checkmark {

                        }

                        .name {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 130%;
                            margin: 2px 0 10px 10px;
                        }
                    }

                    label.inner-checkbox {
                        .checkmark {
                            padding: 17px 18px 14px;
                            height: 110px;
                            .inner-checkmark {
                                .icon {}

                                .text {
                                    padding: 4px 0 0 15px;
                                    .name {
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 14px;
                                        line-height: 150%;
                                    }

                                    .price {
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 130%;
                                    }
                                }
                            }
                        }
                    }
                }

                .order-form-block {
                    padding: 20px 21px 10px;
                    margin-bottom: 6px;
                    .order-form-block-holder {
                        .sub-heading {}

                        .form-group.group-inner-checkbox {
                            width: calc(100% + 30px);
                            margin-left: -15px;
                            margin-bottom: 2px;
                            .form-col {
                                padding: 0 15px;
                                &.col-33 {
                                    width: 50%;
                                }
                                .form-field {
                                    margin: 0 0 16px;
                                }
                            }
                        }

                        .form-group.custom-contacts-cols {
                            .form-col {
                                margin-right: 63px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
