.amount {
    display: inline-block;
    .amount-counter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #DFE5EA;
        box-sizing: border-box;
        border-radius: 30px;
            padding: 7px 10px 6px;
        .decrease, .increase {
            display: inline-block;
            float: left;
            width: 20px;
            height: 20px;
            color: $gray-base;
            &:hover {
                color: $brand-primary;
            }
            svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .increase {

            svg {

            }
        }

        input {
            display: inline-block;
            float: left;
            display: inline-flex;
            float: left;
            width: calc(100% - 40px);
            height: 24px;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: $main_font;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            background: none;
            outline: none;
            border: none;
        }
    }
}
