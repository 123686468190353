@media (max-width: $screen-lg) {
    // lg-md view
    .person-data {
        .person-data-holder {
            form {
                .form-group {
                    width: calc(100% + 30px);
                    margin-left: -15px;
                    .form-col {
                        padding: 0 15px;
                    }
                }

                .action {
                    .btn {
                        width: calc(50% - 15px);
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
