.order-success {
    display: inline-block;
    width: 100%;
    min-height: 420px;
    margin: 11px 0 50px;
    .order-success-holder {
        display: inline-block;
        width: 100%;
        border: 1px dashed #00914C;
        box-sizing: border-box;
        padding: 30px 36% 28px 30px;
        background-image: url(../img/order_success_bkg.svg);
        background-position: 92.5% 60px;
        background-repeat: no-repeat;
        background-size: 200px 128px;
        .heading {
            display: inline-block;
            width: 100%;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 150%;
            margin: 0 0 19px;
            a {
                color: $brand-primary;
                text-decoration: none;
                &:hover {
                    color: $brand-primary-accent;
                }
            }
        }

        .text {
            display: inline-block;
            width: 100%;
            margin: 0 0 11px;
            p {
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 150%;
                a {
                    color: $brand-primary;
                    text-decoration: none;
                    &:hover {
                        color: $brand-primary-accent;
                    }
                }

                br {

                }
            }
        }

        .note {
            display: inline-block;
            width: 100%;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 150%;
        }
    }
}

@import "../../media/tablet/includes/personal/order_success";
@import "../../media/mobile/includes/personal/order_success";
