@media (max-width: $screen-lg) {
    // lg-md view
    .order-success {
        margin: 0px 0 50px;
        min-height: 479px;
        .order-success-holder {
            padding: 30px 36% 28px 29px;
            background-position: 91.5% 98px;
            .heading {
                font-style: normal;
                font-weight: normal;
                font-size: 21px;
                line-height: 150%;
                a {

                }
            }

            .text {
                p {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                }
            }

            .note {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
