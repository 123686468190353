@media (max-width: $screen-lg) {
    // lg-md view
    .personal-page {
        margin: -9px 0 43px;
        .personal-page-holder {
            flex-wrap: wrap;
            .menu-col {
                width: 100%;
                margin: 0 0 16px;
                .menu-col-holder {
                    border: none;
                    .greetings {
                        br {
                            display: none;
                        }
                    }

                    .personal-menu {
                        .personal-menu-holder {
                            ul {
                                width: calc(100% + 20px);
                                margin-left: -10px;
                                li {
                                    width: auto;
                                    float: left;
                                    margin: 0 10px;
                                    a {
                                        border: 1px solid #DFE5EA;
                                        box-sizing: border-box;
                                        border-radius: 30px;
                                        min-height: auto;
                                        padding: 6px 15px 7px 10px;
                                        &.current {
                                            background: white;
                                            border: 1px solid #00914C;
                                            .icon {
                                                color: $brand-primary;
                                            }
                                            .text {
                                                color: $brand-primary;
                                                font-weight: 600;
                                            }
                                        }
                                        .icon {
                                            margin-right: 5px;
                                        }
                                        .text {

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .personal-info {
                width: 100%;
                .personal-info-holder {
                    padding: 0;
                    h2, .h2 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 21px;
                        line-height: 150%;
                    }
                    .note {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 150%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
